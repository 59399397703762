const state = () => ({
  dataProps: [],
})

const getters = {
  dataProps: state => state.dataProps,
}


const actions = {
}

// mutations
const mutations = {
  setDataProps(state, dataProps) {
    state.dataProps = dataProps
  },
}

export default {
  namespaced: true,
  state,
  getters,
  actions,
  mutations
}
