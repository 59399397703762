import i18n from '../locales'

import axios from 'axios'
import store from '../state/store'
import router from '../router/index'

const scriptElement = document.getElementById('rentmagic-shop')
const rentmagicenvironmentUrl = scriptElement?.getAttribute('data-rentmagicEnvironment')



let axiosCreate = axios.create({
  baseURL: `${rentmagicenvironmentUrl ?? store.state.rentMagicEnvironment}/api/v2`,
  headers: {
    //Authorization: `Bearer ${store.state.token}`
  },
  validateStatus: function(status) {
    return status // Resolve only if the status code is less than 500
  }
})

axiosCreate.interceptors.request.use(async (request) => {
  request.headers['accept-language'] = i18n.locale

  if (
    request.url === "public/registerUser" ||
    request.url.includes("authorization/anonymous") ||
    request.url.includes("authorization/login")
  )
    return request;

  while (store.getters['session/getToken'] === null) {
    await new Promise((resolve) => setTimeout(resolve, 100));
  }

  request.headers.authorization = store.getters['session/getToken'];


  return request
})

axiosCreate.interceptors.response.use(response => {
  if (
    !response.config.url.includes('registerUser') &&
    response.status === 401
  ) {
    logout()
  }

  return response
})

function logout() {
  store.commit('unsetUser')
  store.commit('unsetCart')

  router.push({ name: 'index', query: { sessionExpired: true } })
}

//module.exports = { axiosCreate }
export {axiosCreate}
