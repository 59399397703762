const scriptElement = document.getElementById('rentmagic-shop')
const srcUrl =
  scriptElement?.src?.replace('js/app.js', '') ?? '/'


if (srcUrl && process.env.NODE_ENV !== 'development') {
  // eslint-disable-next-line no-undef
  __webpack_public_path__ = srcUrl
}

import "./assets/scss/overrides.scss"
import './assets/scss/fontawesome.scss'

import Vue from 'vue'
import store from './state/store'
import { setupRouter } from './router'
import './registerServiceWorker'

import BreadCrumbs from '@/components/header/BreadCrumbs'
import VueMeta from 'vue-meta'
import VueGtag from 'vue-gtag'
import i18n from './locales/index'

import onlyInt from 'vue-input-only-number'

import '@/globalComponents'

import Toasted from 'vue-toasted'

import './assets/styles/index.css'
import "./widget-listeners/index.js"

Vue.component('bread-crumbs', BreadCrumbs)

Vue.use(VueMeta)
Vue.use(onlyInt)

Vue.use(Toasted, {
  position: 'bottom-right',
  duration: 5000
})

Vue.use(VueGtag, {
  config: { id: process.env.VUE_APP_GTM },
  bootstrap: store.getters.getAcceptedCookiesState
})

Vue.config.productionTip = false

let defaultPageName = null
let basePath = null
let router = null
let siteName = null
let vat = null

export async function mountComponent({ Component, element, dataProps }) {
  if (dataProps.page) {
    defaultPageName = dataProps.page
  }

  if (dataProps.path) {
    basePath = dataProps.path
    window.basePath = dataProps.path
  }

  if(dataProps.vat) {
    vat = dataProps.vat
  }

  if (dataProps.rentmagicenvironment) {
    await store.commit('setRentMagicEnvironmentUrl', dataProps.rentmagicenvironment)
  }

  if (dataProps.sitename) {
    siteName = dataProps.sitename
  }

  if (!router) {
    router = setupRouter({ defaultPageName, basePath })
  }

  new Vue({
    router,
    store,
    i18n,
    render: h => h(Component, { props: { dataProps: dataProps } })
  }).$mount(element)
}

export async function mountComponents({ selector, componentFetcher }) {
  const elements = document.querySelectorAll(selector)

  if (!elements.length) return

  const Component = await componentFetcher()

  const componentObjects = []

  for (let i = 0; i < elements.length; i++) {
    const element = elements[i]
    const dataProps = {}
    // get all data-* attributes and set they as key value pair in dataProps
    for (let j = 0; j < element.attributes.length; j++) {
      const attribute = element.attributes[j]
      if (attribute.name.startsWith('data-')) {
        dataProps[attribute.name.substring(5)] = attribute.value
      }
    }
    componentObjects.push({ Component, element, dataProps })
  }

  for (const component of componentObjects) {
    mountComponent(component)
  }

  if (siteName) {
    store.commit('setSiteName', siteName)
  }



  if (vat) {
    store.commit('setVat', vat === 'true')
  }

}

async function mountAllComponents() {
  //mountComponents('#app', () => import('./App.vue').then(x => x.default))
  await mountComponents({
    selector: '[data-rentmagic-shop-content]',
    componentFetcher: () => import('./Content.vue').then(x => x.default)
  })
  mountComponents({
    selector: '[data-rentmagic-shop-cart-icon]',
    componentFetcher: () =>
      import('./components/header/ButtonShoppingCart.vue').then(x => x.default)
  })
  mountComponents({
    selector: '[data-rentmagic-shop-logout-icon]',
    componentFetcher: () =>
      import('./components/header/ButtonLogout.vue').then(x => x.default)
  })

  mountComponents({
    selector: '[data-rentmagic-shop-account-icon]',
    componentFetcher: () =>
      import('./components/header/ButtonAccount.vue').then(x => x.default)
  })


}

mountAllComponents()

if (!store.getters['session/getToken']) {
  store.dispatch("session/requestAnonymousToken");
}



