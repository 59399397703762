// initial state
import {getAnonymousToken} from "@/services/AuthorizationService"

const state = () => ({
  token: null,
  anonymousToken: null,
})

// getters
const getters = {
  getToken: (state) => state.token ?? state.anonymousToken,
  loggedIn: (state, getters, rootState) => {
    if (rootState.account?.Email) return true
    return false
  }
}

// actions
const actions = {
  async requestAnonymousToken(context) {
    const result = await getAnonymousToken()
    context.commit("setToken", `Bearer ${result.Token.Access_token}`);

    // request a cart so that warehouse and start/end date are set in the store

  },
  async logout({ commit }) {
    await commit('unsetCart', {}, { root: true })
    await commit('unsetUser', {}, { root: true })
    await commit('setBearerToken', {token: null}, { root: true })
    await commit('setToken', null)
    await commit(
      'unsetUser',
      {
        reason: 'loggedOut'
      },
      { root: true }
    )
    return true
  }
}

// mutations
const mutations = {
  setToken(state, token) {
    state.token = token;
  },

}

export default {
  namespaced: true,
  state,
  getters,
  actions,
  mutations
}
