import VueI18n from 'vue-i18n'
import Vue from 'vue'

import nl from './nl.json'

Vue.use(VueI18n)

const messages = {
  nl
}
export default new VueI18n({
  locale: 'nl',
  messages,
  fallbackLocale: 'nl'
})
