const state = {
  activeWarehouse: '',
  activeReturnWarehouse: '',
  accounts: [],
  account: {},
  acceptedCookies: false,
  anonymousToken: null,
  bearerToken: null,
  rentMagicEnvironment: null,
  cartCache: {},
  cartCount: 0,
  cart: [],
  contactID: null,
  vat: true,
  customerID: null,
  siteName: 'Shop',
  DateTimeBusinessStart: '',
  DateTimeBusinessEnd: '',
  DateTimeExpectedStart: '',
  DateTimeExpectedEnd: '',
  lastVisitDate: new Date(),
  user: {},

  mutationProblems: [],

  reservation: {},

  searchTerm: null,
  gridListing: true,
  reservations: null,
  subUsers: [],
  toastMessage: null,
  role: '',
  itemStore: {},

  loadingDateWarehouse: false,
  warehouses: [
    {
      value: '',
      text: 'Kies een locatie',
      disabled: true
    }
  ],
  temporaryWarehouses: []
}

export default state
