import { getWarehouses } from "@/services/WarehouseService";
const state = () => ({
  warehouses: [],
});

const getters = {
  getWarehouses: (state) => state.warehouses,
};

const actions = {
  async fetchWarehouses({ commit }) {
    const warehouses = await getWarehouses();
    commit("setWarehouses", warehouses.Collection);
  },
};

const mutations = {
  setWarehouses(state, warehouses) {
    state.warehouses = warehouses ?? [];
  },
};

export default {
  namespaced: true,
  state,
  getters,
  actions,
  mutations,
};
