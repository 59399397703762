import merge from 'lodash.merge'
import { getCart } from '@/services/CartService'


const mutations = {
  acceptCookies(state) {
    state.acceptedCookies = true
  },
  setTemporaryWarehouses(state, { warehouses }) {
    state.temporaryWarehouses = warehouses
  },
  setSiteName(state,  siteName ) {
    state.siteName = siteName
  },
  setUser(state, { user }) {
    state.user = user
    state.contactID = state.user.Contacts[0].ContactID
    state.accounts = state.user.Accounts.Collection
    state.account = state.accounts.find(
      account => account.ContactID == state.contactID
    )
    state.customerID = state.account.CustomerID
  },
  setAccounts(state, { accounts }) {
    state.accounts = accounts
    state.account = state.accounts.find(
      account => account.UserEmail == state.user.UserEmail
    )
  },
  setVat(state, vat) {
    state.vat = vat
  },
  setLoadingDateWarehouse(state, { value }) {
    state.loadingDateWarehouse = value
  },
  sendToast(state, { toastMessage }) {
    state.toastMessage = toastMessage
  },
  setMutationProblems(state, { mutationProblems }) {
    state.mutationProblems = mutationProblems
  },
  setWarehouses(state, { warehouses }) {
    state.warehouses = warehouses
  },
  updateUser(state, { user }) {
    state.user = merge(state.user, user)
  },
  updateAccount(state, { account }) {
    state.user = merge(state.account, account)
  },
  toggleGridListing(state) {
    if (state.gridListing) {
      state.gridListing = false
    } else {
      state.gridListing = true
    }
  },
  addItemStoreItem(state, { item, key }) {
    state.itemStore[key] = item
  },
  resetStoreItem(state, { key }) {
    delete state.itemStore[key]
  },
  resetStoreItems(state) {
    state.itemStore = {}
  },
  setDefaultCategory(state, { defaultCategory }) {
    state.defaultCategory = defaultCategory
  },
  setRentMagicEnvironmentUrl(state, rentMagicEnvironment) {
    state.rentMagicEnvironment = rentMagicEnvironment
  },
  setContactID(state, { contactID }) {
    state.contactID = contactID
    state.account = state.accounts.find(
      account => account.ContactID == state.contactID
    )
    state.customerID = state.account.CustomerID
    getCart()
  },
  setSearchTerm(state, { searchTerm }) {
    state.searchTerm = searchTerm
  },
  unsetUser(state) {
    state.user = {}
    state.bearerToken = null
    state.contactID = null
    state.accounts = []
    state.account = {}
    state.reservations = null
    state.subUsers = []
    state.customerID = null
    state.customeriD = null
    state.role = null
    state.retrievalTime = ''
    state.returnTime = ''
  },
  setLatestReservation(state, { reservation }) {
    state.reservation = reservation
  },
  setCart(state, { cart }) {
    state.cartCache = cart
    // state.cartCount = cart.CartItems.Collection.filter(
    //   cartItem => cartItem.ItemType !== 'Deposit'
    // ).length
    state.cartCount = cart.CartItems.Collection.filter(
      cartItem => cartItem.ItemType !== 'Deposit'
    ).reduce((a, b) => a + (b['Amount'] || 0), 0)
  },
  unsetCart(state) {
    state.cart = []
    state.cartCount = 0
  },
  setBearerToken(state, { token }) {
    state.bearerToken = token
  },
  unsetBearerToken(state) {
    state.bearerToken = ''
  },
  setDateTimeBusinessStart(state, payload) {
    state.DateTimeBusinessStart = payload.date
  },
  unsetDateTimeBusinessStart(state) {
    state.DateTimeBusinessStart = ''
  },
  setDateTimeBusinessEnd(state, payload) {
    state.DateTimeBusinessEnd = payload.date
  },
  unsetDateTimeBusinessEnd(state) {
    state.DateTimeBusinessEnd = ''
  },
  setDateTimeExpectedStart(state, payload) {
    state.DateTimeExpectedStart = payload.date
  },
  unsetDateTimeExpectedStart(state) {
    state.DateTimeExpectedStart = ''
  },
  setDateTimeExpectedEnd(state, payload) {
    state.DateTimeExpectedEnd = payload.date
  },
  unsetDateTimeExpectedEnd(state) {
    state.DateTimeExpectedEnd = ''
  },
  setSubUsers(state, { subUsers }) {
    state.subUsers = subUsers
  },
  setReservations(state, { reservations }) {
    state.reservations = reservations
  },
  setActiveWarehouseID(state, { warehouseID }) {
    state.activeWarehouse = warehouseID
  },
  setActiveReturnWarehouse(state, { warehouseID }) {
    state.activeReturnWarehouse = warehouseID
  },

  setAnonymousToken(state, { token }) {
    state.bearerToken = `Bearer ${token}`
    state.anonymousToken = `Bearer ${token}`
  }
}

export default mutations
