import Vue from 'vue'
import Vuex from 'vuex'
import VuexPersist from 'vuex-persist'

import actions from './actions'
import getters from './getters'
import mutations from './mutations'
import state from './state'

import categories from './modules/categories'
import session from './modules/session'
import warehouses from './modules/warehouses'
import dataprops from "./modules/dataprops"

const vuexPersist = new VuexPersist({
  key: 'rentmagic',
  storage: window.localStorage
})

Vue.use(Vuex)

export default new Vuex.Store({
  actions,
  getters,
  modules: {
    categories,
    dataprops,
    session,
    warehouses
  },
  mutations,
  namespaced: true,
  plugins: [vuexPersist.plugin],
  state
})
