const getters = {
  cartCache: state => state.cartCache,
  activeWarehouse: state => state.cartCache.WarehouseID,

  user: state => state.user,
  token: state => state.bearerToken,
  cartCount: state => state.cartCount,
  DateTimeBusinessStart: state => state.DateTimeBusinessStart,
  DateTimeBusinessEnd: state => state.DateTimeBusinessEnd,
  DateTimeExpectedStart: state => state.DateTimeExpectedStart,
  DateTimeExpectedEnd: state => state.DateTimeExpectedEnd,
  contactID: state => state.contactID,
  accounts: state => state.accounts,
  account: state => state.account,
  reservation: state => state.reservation,
  customerID: state => state.customerID,
  searchTerm: state => state.searchTerm,
  gridListing: state => state.gridListing,
  vat: state => state.vat,
  subUsers: state => state.subUsers,
  getItemStoreItem: state => key => {
    return state.itemStore[key]
  },
  getAcceptedCookiesState: state => state.acceptedCookies,
  activeReturnWarehouse: state =>
    state.cartCache.ReturnWarehouseID ?? state.cartCache.WarehouseID
}

export default getters
