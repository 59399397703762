import Vue from 'vue'
import VueRouter from 'vue-router'

Vue.use(VueRouter)

const routes = [
  {
    path: '/catalog',
    name: 'catalog',
    component: () => import('../views/Category/Index.vue')
  },
  {
    path: '/categories',
    name: 'categories',
    component: () => import('../views/Categories/Index.vue')
  },
  {
    path: '/category/:categoryID',
    name: 'category',
    component: () => import('../views/Category/Index.vue')
  },
  {
    path: '/artikel/:itemID',
    name: 'uncategorizedItem',
    component: () => import('../views/Item/Index.vue')
  },
  {
    path: '/category/:categoryID/:subCategoryID',
    name: 'subCategory',
    component: () => import('../views/Category/Index.vue')
  },
  {
    path: '/:categoryID/artikel/:itemID',
    name: 'categorizedItem',
    component: () => import('../views/Item/Index.vue')
  },
  {
    path: '/:categoryID/:subCategoryID/:itemID',
    name: 'subCategorizedItem',
    component: () => import('../views/Item/Index.vue')
  },

  {
    path: '/new-sub',
    name: 'newSub',
    // route level code-splitting
    // this generates a separate chunk (about.[hash].js) for this route
    // which is lazy-loaded when the route is visited.
    component: () => import('../views/Account/SubUsers/NewSub.vue')
  },
  {
    path: '/edit-sub',
    name: 'editSub',
    // route level code-splitting
    // this generates a separate chunk (about.[hash].js) for this route
    // which is lazy-loaded when the route is visited.
    component: () => import('../views/Account/SubUsers/EditSub.vue')
  },
  {
    path: '/login',
    name: 'login',
    // route level code-splitting
    // this generates a separate chunk (about.[hash].js) for this route
    // which is lazy-loaded when the route is visited.
    component: () => import('../views/Login/Index.vue'),
    beforeEnter(to, from, next) {
      if (window.basePath && !window.location.href?.includes(`/${window.basePath}`)) {
        window.location = `/${window.basePath}${to.fullPath}`
      }
      next()
    }
  },
  {
    path: '/login/',
    name: 'login-with-slash',
    // route level code-splitting
    // this generates a separate chunk (about.[hash].js) for this route
    // which is lazy-loaded when the route is visited.
    component: () => import('../views/Login/Index.vue')
  },
  {
    path: '/maintenance-login',
    name: 'maintenance-login',
    // route level code-splitting
    // this generates a separate chunk (about.[hash].js) for this route
    // which is lazy-loaded when the route is visited.
    component: () => import('../views/AnonymousLogin/Index.vue')
  },
  {
    path: '/register',
    name: 'register',
    // route level code-splitting
    // this generates a separate chunk (about.[hash].js) for this route
    // which is lazy-loaded when the route is visited.
    component: () => import('../views/Register/Index.vue')
  },
  {
    path: '/reservation/:reservationID',
    name: 'reservation',
    // route level code-splitting
    // this generates a separate chunk (about.[hash].js) for this route
    // which is lazy-loaded when the route is visited.
    component: () => import('../views/Account/Reservations/Reservation.vue')
  },
  {
    path: '/employee-reservation/:voucher',
    name: 'employee-reservation',
    // route level code-splitting
    // this generates a separate chunk (about.[hash].js) for this route
    // which is lazy-loaded when the route is visited.
    component: () =>
      import('../views/Account/EmployeeReservations/EmployeeReservation.vue')
  },
  {
    path: '/account',
    name: 'account',
    // route level code-splitting
    // this generates a separate chunk (about.[hash].js) for this route
    // which is lazy-loaded when the route is visited.
    components: {
      default: () => import('../views/Account/Index.vue'),
      account: () => import('../views/Account/User/Index.vue')
    },
    beforeEnter(to, from, next) {
      if (window.basePath && !window.location.href?.includes(`/${window.basePath}`)) {
        window.location = `/${window.basePath}${to.fullPath}`
      }
      next()
    },
    children: [
      {
        alias: '',
        path: '/user',
        name: 'account-user',
        // route level code-splitting
        // this generates a separate chunk (about.[hash].js) for this route
        // which is lazy-loaded when the route is visited.
        components: {
          default: () => import('../views/Account/Index.vue'),
          account: () => import('../views/Account/User/Index.vue')
        },
        beforeEnter(to, from, next) {
          if (window.basePath && !window.location.href?.includes(`/${window.basePath}`)) {
            window.location = `/${window.basePath}${to.fullPath}`
          }
          next()
        },
      },
      {
        path: '/organisation',
        name: 'account-organisation',
        components: {
          default: () => import('../views/Account/Index.vue'),
          account: () => import('../views/Account/Organisation/Index.vue')
        }
      },
      {
        path: '/reservations',
        name: 'account-reservations',
        components: {
          default: () => import('../views/Account/Index.vue'),
          account: () => import('../views/Account/Reservations/Index.vue')
        }
      },
      {
        path: '/employee-reservations',
        name: 'employee-reservations',
        components: {
          default: () => import('../views/Account/Index.vue'),
          account: () =>
            import('../views/Account/EmployeeReservations/Index.vue')
        }
      },
      {
        path: '/voucher',
        name: 'account-voucher',
        // route level code-splitting
        // this generates a separate chunk (about.[hash].js) for this route
        // which is lazy-loaded when the route is visited.
        components: {
          default: () => import('../views/Account/Index.vue'),
          account: () => import('../views/Account/Voucher/Index.vue')
        }
      },
      {
        path: '/organisation',
        name: 'account-organisation',
        components: {
          default: () => import('../views/Account/Index.vue'),
          account: () => import('../views/Account/Organisation/Index.vue')
        }
      },
      {
        path: '/sub-users',
        name: 'account-sub-users',
        components: {
          default: () => import('../views/Account/Index.vue'),
          account: () => import('../views/Account/SubUsers/Index.vue')
        }
      }
    ]
  },
  {
    path: '/betaling/:OrderReservationID',
    name: 'betaling',
    // route level code-splitting
    // this generates a separate chunk (about.[hash].js) for this route
    // which is lazy-loaded when the route is visited.
    component: () => import('../views/Account/Payment.vue')
  },
  {
    path: '/cart',
    name: 'cart',
    // route level code-splitting
    // this generates a separate chunk (about.[hash].js) for this route
    // which is lazy-loaded when the route is visited.
    component: () => import('../views/Cart/Index.vue'),
    beforeEnter(to, from, next) {
      if (window.basePath && !window.location.href?.includes(`/${window.basePath}`)) {
        window.location = `/${window.basePath}${to.fullPath}`
      }
      next()
    }
  },
  {
    path: '/cart-login',
    name: 'cart-login',
    // route level code-splitting
    // this generates a separate chunk (about.[hash].js) for this route
    // which is lazy-loaded when the route is visited.
    component: () => import('../views/Cart/AnonymousConfirmation.vue')
  },
  {
    path: '/anonymous-checkout',
    name: 'anonymous-checkout',
    component: () => import('../views/Cart/AnonymousCheckout.vue')
  },
  {
    path: '/confirmation',
    name: 'confirmation',
    // route level code-splitting
    // this generates a separate chunk (about.[hash].js) for this route
    // which is lazy-loaded when the route is visited.
    component: () => import('../views/Cart/Confirmation.vue')
  },
  {
    path: '/profiel-keuze',
    name: 'profileSelector',
    // route level code-splitting
    // this generates a separate chunk (about.[hash].js) for this route
    // which is lazy-loaded when the route is visited.
    component: () => import('../views/Login/ProfileSelector.vue')
  },

  {
    path: '/reservering-confirmatie',
    name: 'reservation-confirmation',
    // route level code-splitting
    // this generates a separate chunk (about.[hash].js) for this route
    // which is lazy-loaded when the route is visited.
    component: () => import('../views/Cart/ReservationConfirmation.vue')
  },
  {
    path: '/zoeken/:searchTerm',
    name: 'search',
    // route level code-splitting
    // this generates a separate chunk (about.[hash].js) for this route
    // which is lazy-loaded when the route is visited.
    component: () => import('../views/Category/Index.vue')
  },
  {
    path: '/wachtwoord-herstellen/:code',
    name: 'newPassword',
    // route level code-splitting
    // this generates a separate chunk (about.[hash].js) for this route
    // which is lazy-loaded when the route is visited.
    component: () => import('../views/Login/ResetPassword.vue')
  },
  {
    path: '/wachtwoord-herstellen/',
    name: 'newPassword',
    // route level code-splitting
    // this generates a separate chunk (about.[hash].js) for this route
    // which is lazy-loaded when the route is visited.
    component: () => import('../views/Login/ResetPassword.vue')
  },
  {
    path: '/wachtwoord-herstellen',
    name: 'forgotPassword',
    // route level code-splitting
    // this generates a separate chunk (about.[hash].js) for this route
    // which is lazy-loaded when the route is visited.
    component: () => import('../views/Login/ForgotPassword.vue')
  },
  {
    path: '/account-aangemaakt',
    name: 'accountCreated',
    // route level code-splitting
    // this generates a separate chunk (about.[hash].js) for this route
    // which is lazy-loaded when the route is visited.
    component: () => import('../views/Register/AccountCreated.vue')
  },
  {
    path: '/account-aangemaakt/:code',
    name: 'accountCreated',
    // route level code-splitting
    // this generates a separate chunk (about.[hash].js) for this route
    // which is lazy-loaded when the route is visited.
    component: () => import('../views/Register/AccountCreated.vue')
  },
  {
    path: '/account-email-verificatie',
    name: 'accountEmailSend',
    // route level code-splitting
    // this generates a separate chunk (about.[hash].js) for this route
    // which is lazy-loaded when the route is visited.
    component: () => import('../views/Register/AccountEmailSend.vue')
  }
]

export function setupRouter({ defaultPageName, basePath }) {
  const defaultRoute = defaultPageName ?? 'catalog'
  const defaultRouteIndex = routes.findIndex(
    route => route.name === defaultRoute
  )

  routes[defaultRouteIndex].path = '/'

  const router = new VueRouter({
    base: basePath ?? '/',
    mode: 'history',
    routes
  })


  return router
}
