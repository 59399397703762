import { getAnonymousToken } from '@/services/AuthorizationService'
import { copyAnonymousCart, getCart } from '@/services/CartService'
//import router from '@/router/'

const actions = {
  async requestAnonymousToken(context) {
    const result = await getAnonymousToken()

    context.commit('setAnonymousToken', {
      token: result.data.Token.Access_token
    })

    //request a cart so that warehouse and start/end date are set in the store
    await getCart()
  },
  async copyCart() {
    await copyAnonymousCart()
  },
  forwardToLoginWhenAnonymous({ state }) {
    if (!state.user.Email) {
    //  router.push({ name: 'login' })
    }
  }
}

export default actions
