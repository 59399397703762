import store from "@/state/store";

// document on click [data-action-toggle-vat] store.commit("setVat", !store.state.vat)
document.addEventListener("click", event => {
  const target = event.target;
  if (target.dataset.rentmagicShopAction === 'toggleVat') {
    store.commit("setVat", !store.state.vat);
  }
})


